









import Vue from 'vue';

export default Vue.extend({
  computed: {
    currentLanguage(): string {
      return this.$translation.get();
    },
  },
  methods: {
    setLanguage(languageCode: string) {
      this.$translation.set(languageCode);
    },
  },
});
